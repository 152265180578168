<template>
  <v-container fluid>
    <v-row
      :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'mt-6' : ''"
      v-for="(filtro, index) in filtros"
      :key="index"
    >
      <v-col
        md="3"
        cols="12"
        class="py-0"
      >
        <v-autocomplete
          class="mt-5"
          :items="camposOrdenados"
          item-text="column"
          v-model="filtro.campo"
          @change="limparTerceiroGrupoInputsValores(filtro, index)"
          @input="(campo) => updateFiltroType(filtro, campo)"
          dense
          label="Campo"
          no-data-text="Nenhum campo disponível"
        />
      </v-col>
      <v-col
        md="3"
        cols="12"
        class="py-0"
      >
        <v-autocomplete
          :items="filtrosDisponiveisToCampo(filtro.campo)"
          v-model="filtro.funcao"
          @change="limparTerceiroGrupoInputsValores(filtro, index)"
          item-value="value"
          item-text="text"
          label="Filtro"
          no-data-text="Nenhum filtro disponível"
        />
      </v-col>
      <v-col
        md="5"
        sm="11"
        cols="10"
        class="py-0"
      >
        <input-group-valor
          :ref="`refInpoutGroupValor${index}`"
          :index="index"
          :filtro.sync="filtros[index]"
        />
      </v-col>
      <v-col
        sm="1"
        cols="2"
        class="mt-2 py-0"
        id="btn-add-remove-filtro"
      >
        <v-row>
          <v-col cols="6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#2B60D6"
                  min-width="0"
                  icon
                  fab
                  x-small
                  @click="addFiltro(index)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Adicionar Filtro</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="index > 0"
                  color="error"
                  min-width="0"
                  icon
                  fab
                  x-small
                  @click="removeFiltro(index)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-minus</v-icon>
                </v-btn>
              </template>
              <span>Remover Filtro</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  name: 'CamposFiltros',

  mixins: [dateFormatterMixins],

  components: {
    InputGroupValor: () => import('@/components/relatorios/InputGroupValor')
  },

  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    },
    campos: {
      type: Array,
      default: () => []
    },
    baseDados: {
      type: String,
      default: ''
    },
    filtrosSelecionados: {
      type: Array
    }
  },

  data: () => ({
    filtros: [
      {
        campo: '',
        funcao: '',
        valor: '',
        type: '',
        valorFinal: '',
        date: '',
        date2: '',
        dateFormatted: '',
        dateFormatted2: '',
        month: '',
        monthFormatted: '',
        month2: '',
        monthFormatted2: ''
      }
    ],
    filtrosFactiveis: [
      {
        function: '=',
        translate: '=',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '≠',
        translate: '<>',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '≥',
        translate: '>=',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: '≤',
        translate: '<=',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: '>',
        translate: '>',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: '<',
        translate: '<',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: 'Entre',
        translate: 'BETWEEN',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: 'Lista',
        translate: 'IN',
        types: [
          'smallint',
          'integer',
          'bigint',
          'character',
          'character varying',
          'text'
        ]
      },
      {
        function: 'Fora da Lista',
        translate: 'NOT IN',
        types: [
          'smallint',
          'integer',
          'bigint',
          'character',
          'character varying',
          'text'
        ]
      },
      {
        function: 'Vazio',
        translate: 'IS NULL',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: 'Não Vazio',
        translate: 'IS NOT NULL',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: 'Contém',
        translate: 'ILIKE %X%',
        types: ['character', 'character varying', 'text']
      },
      {
        function: 'Não contém',
        translate: 'NOT ILIKE %X%',
        types: ['character', 'character varying', 'text']
      },
      {
        function: 'Começa com',
        translate: 'ILIKE X%',
        types: ['character', 'character varying', 'text']
      },
      {
        function: 'Termina com',
        translate: 'ILIKE %X',
        types: ['character', 'character varying', 'text']
      }
    ],
    campoDataBase: 'mes_dados'
  }),

  mounted() {
    if (this.$route.params.id) {
      this.filtros = this.filtrosSelecionados[0];
    } else {
      this.criaFiltrosVersaoBdgd();
    }
  },

  computed: {
    camposOrdenados() {
      let campos = [...this.campos];
      return campos.sort((a, b) =>
        a.column.toUpperCase() > b.column.toUpperCase() ? 1 : -1
      );
    },
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },

  methods: {
    filtrosDisponiveisToCampo(campo) {
      if (!campo) return [];

      let infoCampoSelecionado = this.campos.find((e) => e.column === campo);
      if (!infoCampoSelecionado) return [];

      let arrayFiltros = this.filtrosFactiveis.filter((el) =>
        el.types.includes(infoCampoSelecionado.type)
      );
      return arrayFiltros.map((e) => {
        return { text: e.function, value: e.translate };
      });
    },

    updateFiltroType(filtro, campo) {
      this.campos.forEach((item) => {
        if (item.column == campo) filtro.type = item.type;
      });
      this.$forceUpdate();
    },

    limparTerceiroGrupoInputsValores(filtro, index) {
      setTimeout(() => {
        filtro.date =
          filtro.date2 =
          filtro.dateFormatted =
          filtro.dateFormatted2 =
          filtro.month =
          filtro.month2 =
          filtro.monthFormatted =
          filtro.monthFormatted2 =
          filtro.valor =
          filtro.valorFinal =
            '';
        this.$refs['refInpoutGroupValor' + index][0].render();
        this.$forceUpdate();
      }, 400);
    },

    addFiltro(index) {
      this.filtros.push(this.getEmptyFilter());
      this.$refs['refInpoutGroupValor' + index][0].addMenus();
    },

    removeFiltro(target) {
      if (this.filtros.length <= 1) {
        this.filtros = [this.getEmptyFilter()];
      } else {
        this.filtros.splice(target, 1);
        this.$refs['refInpoutGroupValor' + target][0].removeMenus(target);
      }
    },

    getEmptyFilter() {
      return {
        campo: '',
        funcao: '',
        valor: '',
        type: '',
        valorFinal: '',
        date: '',
        date2: '',
        dateFormatted: '',
        dateFormatted2: '',
        month: '',
        monthFormatted: '',
        month2: '',
        monthFormatted2: ''
      };
    },

    criarFiltrosDefault(dadosFiltro) {
      this.filtros = [];
      let emptyFilter = this.getEmptyFilter();

      if (JSON.stringify(dadosFiltro) == '[]') this.filtros[0] = emptyFilter;

      if ('mes_dados' in dadosFiltro) {
        this.filtros.push({
          ...emptyFilter,
          ...{
            campo: 'mes_dados',
            funcao: '=',
            type: 'date',
            month: this.formatMonthToPicker(dadosFiltro.mes_dados), //formato esperado - ####-##
            monthFormatted: this.formatMonth(dadosFiltro.mes_dados), //formato esperado - ##/####
            valor: dadosFiltro.mes_dados //formato esperado - ####-##-##
          }
        });
      }

      if ('bdgd_versao' in dadosFiltro) {
        this.filtros.push({
          ...emptyFilter,
          ...{
            campo: 'bdgd_versao',
            funcao: '=',
            type: 'integer',
            valor: dadosFiltro.bdgd_versao
          }
        });
      }

      this.$forceUpdate();
    },

    criaFiltrosVersaoBdgd() {
      if (this.userSelectedCompanyBdgdVersion) {
        const { data_registro, versao } = this.userSelectedCompanyBdgdVersion;
        dadosFiltro = {
          mes_dados: dayjs(data_registro).format('YYYY-MM-DD'),
          bdgd_versao: versao
        };
        this.criarFiltrosDefault(dadosFiltro);
      } else if (this.month && this.bdgdVersion) {
        this.criarFiltrosDefault({
          mes_dados: this.month,
          bdgd_versao: this.bdgdVersion
        });
      }
    }
  },

  watch: {
    month() {
      this.criaFiltrosVersaoBdgd();
    },

    bdgdVersion() {
      this.criaFiltrosVersaoBdgd();
    },

    filtros() {
      this.$emit('filtrosAtualizados', this.filtros);
    }
  }
};
</script>
